import React from 'react'
import styled from 'styled-components'
import LocationSvg from './svgs/LocationSvg'

const Footer = () => {
    return (
        <Foot>
            <Footer1>
                <p>
                    Find us at: <LocationSvg /> 2/40 Lionel St, Ingleburn NSW 2508
                </p>
            </Footer1>
            <Footer2>
                <p>© John Oste Engineering {currentYear}</p>
            </Footer2>
        </Foot>
    )
}
const currentYear = new Date().getFullYear(); 
const Foot = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: flex-end;
    padding: 0rem 10rem;
    min-height: 10vh;
    background: rgba(0, 0, 0, 0.8);
    p {
        font-size: 1rem;
    }
    @media (max-width: 1000px) {
        padding: 0 4rem;
    }
    @media (max-width: 630px) {
        text-align: center;
        display: block;
        p {
            font-size: 0.7rem;
        }
    }
`
const Footer1 = styled.div`
    display: flex;
    justify-content: flex-start;
    @media (max-width: 630px) {
        justify-content: center;
    }
`
const Footer2 = styled.div`
    display: flex;
    justify-content: center;
`
// const Footer3 = styled.div`
//     display: flex;
//     justify-content: center;
// `

export default Footer
